<script setup lang="ts">
import DsWindows from "~templates/components/pophover/ds-windows.vue";
import {computed, ref} from "vue";
import DsInput from "~templates/components/form/input/ds-input.vue";

const props = defineProps({
  slug: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: false,
  },
});

const show_help = ref(false);

const result = computed(() => {
  const trim_textarea = textarea.value.replace(/\s+/g, '');
  const rawTimeSegments = trim_textarea.match(/(-?\d+h)|(-?\d+m)|(-?\d+)/g) || [];

  const timeSegments = rawTimeSegments.map((segment: string, index) => {
    if (!segment.endsWith('h') && !segment.endsWith('m')) {
      const sign = (index > 1 &&
          rawTimeSegments[index - 1].endsWith('h') &&
          rawTimeSegments[index - 1].startsWith('-')) ? '-' : '';
      return sign + segment + 'm';
    }
    return segment;
  }).flat().filter(Boolean);

  let totalMinutes = 0;

  timeSegments.forEach(segment => {
    const hours = parseInt(segment.match(/(-?\d+)h/)?.[1] || '0', 10);
    const minutes = parseInt(segment.match(/(-?\d+)m/)?.[1] || '0', 10);
    totalMinutes += hours * 60 + minutes;
  });


  const resultHours = Math.floor(Math.abs(totalMinutes) / 60);
  const resultMinutes = Math.abs(totalMinutes) % 60;
  const sign = totalMinutes < 0 ? '-' : '';

  return `${sign}${resultHours}h${resultMinutes}m`;
});

const textarea = ref('');
</script>

<template>
  <ds-windows :slug="slug" :min_height="200" :initial_height="200" :resizable="false">
    <template #title>
      {{ name ?? 'Calculateur d\'horaire' }}
    </template>
    <template v-if="!show_help">
      <p>Résultat : <span class="font-bold">{{ result }}</span></p>
      <ds-input type="textarea" v-model="textarea"/>
    </template>
    <template v-else>
      <p class="font-bold text-center">Utilisation</p>
      <p class="py-1 text-center">Écrivez de manière naturelle les heures et les minutes que vous souhaitez calculer.
        <br>
        <span class="text-blue-500 cursor-pointer hover:blue-400 hover:underline underline-offset-4"
              @click="textarea = '7h32 + 48m - 4h';show_help = false;">
                Exemple : 7h32 + 48m - 4h
                </span>
      </p>
    </template>
    <p @click="show_help = !show_help" class="text-blue-500 cursor-pointer text-xs underline-offset-4 hover:text-blue-400 hover:underline">
      {{ show_help ? "Cacher l'aide" : "Comment utiliser la calculatrice d'horaire ?" }}
    </p>
  </ds-windows>
</template>

<style scoped>

</style>
