<script setup lang="ts">
import {onMounted, onUnmounted, ref, watch} from "vue";
import {useSessionStorage} from "@vueuse/core";
import {DsWindow} from "~/helper/windowManager";

const props = defineProps({
  slug: String,
  initial_width: {type: Number, default: 300},
  initial_height: {type: Number, default: 300},
  min_height: {type: Number, default: 300},
  min_width: {type: Number, default: 300},
  resizable: {type: Boolean, default: true},
});

const vue_draggable_resizable = ref(null);
const fullsize = ref(false);
const position = useSessionStorage(`ds-windows-${props.slug}-position`,
    {x: 0, y: 0, width: props.initial_width, height: props.initial_height});
const previous_position = useSessionStorage(`ds-windows-${props.slug}-previous-position`,
    {x: 0, y: 0, width: props.initial_width, height: props.initial_height});
const active = ref(false);

watch(fullsize, () => {
  if (fullsize.value) {
    document.body.style.overflow = 'hidden';
    position.value = {x: 0, y: 0, width: window.innerWidth, height: window.innerHeight};
    return
  }
  document.body.style.overflow = 'auto';
  getPreviousPosition();
});

watch(position, () => {
  if (fullsize.value) {
    return
  }
  setTimeout(() => {
    previous_position.value = {...position.value};
  }, 10);
}, {deep: true});

function getPreviousPosition() {
  position.value.width = previous_position.value.width;
  position.value.height = previous_position.value.height;
  setTimeout(() => { // hack pour que la position soit bien prise en compte sinon elle est out of bound et il ne change pas la position
    position.value.x = previous_position.value.x;
    position.value.y = previous_position.value.y;
  }, 1);
}

function onResizing(x, y, width, height) {
  position.value.x = x;
  position.value.y = y;
  position.value.width = width;
  position.value.height = height;
}

function onDragging(x, y) {
  position.value.x = x;
  position.value.y = y;
}

function moveToScreenView() {
  if (position.value.x + position.value.width > window.innerWidth) {
    position.value.x = window.innerWidth - position.value.width;
  }
  if (position.value.y + position.value.height > window.innerHeight) {
    position.value.y = window.innerHeight - position.value.height;
  }
}

onMounted(() => {
  getPreviousPosition();
  moveToScreenView();
  window.addEventListener('resize', () => {
    moveToScreenView();
  });
  window.addEventListener('mousedown', (event) => {
    if (vue_draggable_resizable.value && !vue_draggable_resizable.value.$el.contains(event.target)) {
      active.value = false;
    }
  });
});

onUnmounted(() => {
  window.removeEventListener('mousedown', () => {
    if (vue_draggable_resizable.value && !vue_draggable_resizable.value.$el.contains(event.target)) {
      active.value = false;
    }
  });
});
</script>

<template>
  <vue-draggable-resizable drag-handle=".drag" :z="40 + active" :parent="true" v-if="!DsWindow.isMinimize(props.slug)"
                           :w="position.width" :h="position.height" :min-width="min_width" :min-height="min_height" :draggable="!fullsize"
                           :x="position.x" :y="position.y" @dragging="onDragging" @resizing="onResizing"
                           ref="vue_draggable_resizable" :resizable="resizable"
                           :active="active" @mousedown="active = true;" :disable-user-select="false"
                           :class="['fixed rounded-md border-black border shadow-lg shadow-gray-600',
         'ring-white ring-1 overflow-y-hidden ',{'!rounded-none':fullsize,'opacity-50':!active}]">
    <template #tl>
      <div class="p-1 top-0 left-0  absolute bottom-0 h-2 w-2 z-[12] cursor-nw-resize">
      </div>
    </template>
    <template #tm>
      <div class="p-1  absolute top-0 w-full cursor-n-resize ">
      </div>
    </template>
    <template #tr>
      <div class="p-1 top-0 right-0  absolute bottom-0 h-2 w-2 z-[12] cursor-ne-resize">

      </div>
    </template>

    <template #mr>
      <div class="p-1 right-0  absolute bottom-0 h-full cursor-e-resize">

      </div>
    </template>

    <template #br>
      <div class="p-1 right-0  absolute bottom-0 h-2 w-2 z-[12] cursor-se-resize">

      </div>
    </template>
    <template #bm>
      <div class="p-1  absolute bottom-0 w-full cursor-s-resize">

      </div>
    </template>
    <template #bl>
      <div class="p-1 left-0  absolute bottom-0 h-2 w-2 z-[12] cursor-sw-resize">

      </div>
    </template>
    <template #ml>
      <div class="p-1 left-0  absolute bottom-0 h-full cursor-w-resize">

      </div>
    </template>

    <div class="px-2 bg-black text-white flex items-center drag h-[28px] w-full">
      <div class="flex items-center gap-2 group">
        <div class="h-3 w-3 bg-red-500 saturate-100 hover:bg-red-400 cursor-pointer transition rounded-full flex "
             @click="DsWindow.closeWindow(slug)">
          <i
              class="fa-solid fa-xmark opacity-0 group-hover:opacity-100 m-auto text-[0.65em] mt-0.5  text-red-900"></i>
        </div>
        <div class="h-3 w-3 bg-yellow-500  hover:bg-yellow-400 cursor-pointer rounded-full flex" @click="DsWindow.toggleMinimizeWindow(slug)">
          <i class="fa-solid fa-minus opacity-0 group-hover:opacity-100 m-auto text-[0.65em] mt-0.5  text-yellow-900"></i>
        </div>
        <!--                <div class="h-3 w-3 bg-green-500 saturate-150 hover:bg-green-400 cursor-pointer rounded-full" @click="fullsize = !fullsize">-->
        <!--                </div>-->
      </div>
      <div class="ml-8 select-none">
        <slot name="title">

        </slot>
      </div>
    </div>

    <div :class="['bg-white overflow-y-auto @container p-5',{'grayscale':!active}]" :style="`height: ${position.height -28}px`">
      <slot>

      </slot>
    </div>
  </vue-draggable-resizable>
</template>

<style scoped>

</style>
