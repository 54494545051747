import {useSessionStorage} from "@vueuse/core";
import {computed, markRaw, ref} from "vue";
import WindowCalculHours from "~templates/components/pophover/windows/window-calcul-hours.vue";
import WindowNotes from "~templates/components/pophover/windows/window-notes.vue";
export const window_list = useSessionStorage('window_list',[]);
export const window_minimized_list = computed(() => {
    return window_list.value.filter((window) => window.minimized)
});

export class DsWindow {
     static getComponent(slug: string) {
        return window_component[slug]?.component;
    }
    static getProps(slug: string) {
        return window_component[slug]?.props;
    }
    static isMinimize(slug: string) {
        return window_minimized_list.value.some((window) => window.slug === slug);
    }
    static isOpen(slug: string) {
        return window_list.value.some((window) => window.slug === slug);
    }

    static exist(slug: string) {
        return window_list.value.some((window) => window.slug === slug);
    }

    static toggleMinimizeWindow(slug:string) {
        let index = window_list.value.findIndex((window) => window.slug === slug);
        if (index !== -1) {
            window_list.value[index].minimized = !window_list.value[index].minimized;
        }
    }

    static closeWindow(slug: string) {
        let index = window_list.value.findIndex((window) => window.slug === slug);
        if (index !== -1) {
            window_list.value.splice(index, 1);
        }
    }

    static openWindow(slug: string,name: string = null) {
         if(!name){
             name = slug;
         }
        let index = window_list.value.findIndex((window) => window.slug === slug);
        if(index !== -1){
            window_list.value[index].minimized = false;
            //set the proprety active from the component to true

            return;
        }

        const component = window_component[slug];

        if(!component){
            console.error('Component not found');
            return;
        }

        window_list.value.push({
            name: name,
            slug: slug,
            minimized: false,
        })
    }
}


const window_component = {
    calc_hours:{
        component: WindowCalculHours,
        props: {}
    },
    notes:{
        component: WindowNotes,
        props: {}
    }
}
