<script setup lang="ts">
import {ref} from "vue";

const emit = defineEmits(['update:modelValue']);

const {
    modelValue,
    classInput,
    classLabel,
    type,
    name,
    placeholder,
    required,
    disabled,
    hidden,
    autofocus,
    size
} = defineProps({
    modelValue: [String, Number],
    classInput: {default: '', type: [String, Array, Object]},
    classLabel: {default: '', type: [String, Array, Object]},
    type: {type: String, default: 'text'},
    name: {type: String, default: ''},
    placeholder: {type: String, default: ''},
    required: Boolean,
    disabled: Boolean,
    hidden: Boolean,
    autofocus: Boolean,
    color: {type: String, default: 'border-gray-400 hover:border-primary focus:border-primary'},
    size: {type: String, default: 'md'}
});


const default_class = "w-full rounded-sm border outline-none transition\n" +
    "                  focus:ring-1 focus:ring-primary disabled:grayscale disabled:opacity-40 disabled:cursor-not-allowed"

const paginate_size = ref(null);
const text_size = ref(null);


switch (size) {
    case 'xs':
        paginate_size.value = "py-1 px-1";
        text_size.value = "text-xs";
        break;
    case 'sm':
        paginate_size.value = "py-1.5 px-2 ";
        text_size.value = "text-sm";
        break
    case 'md':
        paginate_size.value = "py-2 px-4";
        text_size.value = "text-md";
        break
    case 'lg':
        paginate_size.value = "py-3 px-6";
        text_size.value = "text-lg";
        break
    case 'xl':
        paginate_size.value = "py-4 px-9";
        text_size.value = "text-xl";
        break
    default:
        paginate_size.value = "py-2 px-4";
        text_size.value = "text-md";
        break;
}

</script>

<template>
    <label :class="['flex-col gap-1 relative block',{'hidden':hidden}]">
        <span :class="[text_size,classLabel]">
            <slot></slot> <span v-if="required && $slots.default"><slot name="required-symbol">*</slot></span>
        </span>
        <span class="flex">
                <textarea v-if="type === 'textarea'"
                          :placeholder="placeholder"
                          :name="name"
                          :value="modelValue"
                          @input="$emit('update:modelValue', $event.target.value)"
                          :class="[default_class,paginate_size,text_size,classInput,color]"
                          :required="required"
                          :disabled="disabled"
                          :autofocus="autofocus">
                    {{modelValue}}
                </textarea>

                <span v-else class="w-full flex items-center">
                  <span :class="['absolute flex items-center start-0 pl-4',{'opacity-40':disabled}]">
                    <slot name="input-prefix">

                    </slot>
                  </span>
                  <input
                      :type="type"
                      :placeholder="placeholder"
                      :name="name"
                      :value="modelValue"
                      @input="$emit('update:modelValue', $event.target.value)"
                      :class="[default_class,paginate_size,text_size,classInput,color]"
                      :required="required"
                      :disabled="disabled"
                      :autofocus="autofocus">
                  <span :class="['absolute flex items-center end-0 pr-4',{'opacity-40':disabled}]">
                    <slot name="input-suffix">

                    </slot>
                  </span>
                </span>
                <slot name="next-input">

                </slot>
            </span>
    </label>
</template>
