<script setup lang="ts">
import DsWindows from "~templates/components/pophover/ds-windows.vue";
import {computed, ref} from "vue";
import DsInput from "~templates/components/form/input/ds-input.vue";
import {useSessionStorage} from "@vueuse/core";

const props = defineProps({
    slug: {
        type: String,
        required: true,
    },
});

const textarea = useSessionStorage(`ds-windows-${props.slug}-textarea`, '');
</script>

<template>
    <ds-windows :slug="slug" :min_height="400" :initial_height="400" :initial_width="400" :min_width="400"
                :resizable="true">
        <template #title>
            Outils de test
        </template>

        <ds-input type="textarea" v-model="textarea"></ds-input>
    </ds-windows>
</template>

<style scoped>

</style>
